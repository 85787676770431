import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const PelexLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Erin Wolff"
    institution="Pelex"
    referralCode="PELEX"
    physicianURL="https://www.pelexmed.com/"
  />
)

export default PelexLandingPage
